
import Vue from 'vue';
import { C360Provider, defineAbilityFor } from '@c360/ui';
import utils from './../../util';

export default Vue.extend({
  components: {
    AdminToolbar: () => import('../global/adminToolbar.vue'),
    AppGlobals: () => import('../global/globals.vue'),
    FooterC360: () => import('../global/footerC360.vue'),
    ProjectVersion: () => import('../global/projectVersion.vue'),
    Status: () => import('../global/status.vue'),
    C360Provider,
  },
  data: () => ({
    refreshToken: '',
    productId: 5,
    config: {
      whatIsNewDialog: {
        page: '',
      },
    },
  }),
  computed: {
    accessToken() {
      return localStorage.getItem('ah:accessToken');
    },
    refreshTokenLS() {
      return localStorage.getItem('ah:refreshToken');
    },
    themeCode(): string {
      return window.location.hostname?.split('.')[0];
    },
    ability() {
      return defineAbilityFor({
        isSuperUser: this.$store.state?.customer?.user?.is_superuser ?? false,
        isAgencyAdmin: utils.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ?? false,
        products: this.$store.state?.customer?.user?.products ?? [],
        permissions: this.$store.state?.customer?.user?.permissions ?? [],
        activeAgencyName: this.$store.state?.customer?.user?.active_agency_id ?? '',
        tenantsCount: this.$store.state?.customer?.user?.AvailableAgencies?.length ?? 1,
      });
    },
    c360Path(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
  },
  created() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).adminApp = this;
  },
  mounted() {
    this.refreshToken = this.$store.state?.customer?.user?.refreshToken;
    setTimeout(() => {
      this.config = {
        whatIsNewDialog: {
          page: `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`,
        },
      };
    }, 500);
  },
  watch: {
    '$store.state.customer.user.accessToken': {
      handler(newState: boolean): void {
        this.refreshToken = newState;
      },
    },
  },
});
